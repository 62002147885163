/*
App-custom.css
Template name: AppUI
Author: rustheme
*/

//
// Here you can write your own Css / scss
// -------------------------

// Core variables and mixins
@import "variables";
@import "mixins";
@import "bootstrap/mixins";
